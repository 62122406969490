.edit-button {
  color: #0096a9;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #dbf8fb;
  border: none;
  border-radius: 100%;
  padding: 0px;
  transition: 0.3s;
  margin-right: 0.25rem;
}

.edit-button:hover {
  background-color: #98f2fc;
  color: #0096a9;
}

.edit-button:focus {
  color: #0096a9;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #dbf8fb;
  border: none;
  border-radius: 100%;
  padding: 0px;
  transition: 0.3s;
}
