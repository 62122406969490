.delete-button {
  color: #ba4084;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #f6e4ee;
  border: none;
  border-radius: 100%;
  padding: 0px;
  transition: 0.3s;
}

.delete-button:hover {
  background-color: #f7c1d5;
  color: #ba4084;
}

.delete-button:focus {
  color: #ba4084;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #f6e4ee;
  border: none;
  border-radius: 100%;
  padding: 0px;
  transition: 0.3s;
}

.delete-disabled {
  opacity: 0.5;
  color: #ba4084;
  width: 2.5rem;
  height: 2.5rem;
  background-color: #f6e4ee;
  border: none;
  border-radius: 100%;
  padding: 0px;
  pointer-events: none;
}
