body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", -apple-system,
    BlinkMacSystemFont, "Segoe UI", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans",
    "Droid Sans", "Helvetica Neue", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
  background-image: url("./FLM_lijnen2.svg");
}
html {
  scroll-behavior: smooth;
}
