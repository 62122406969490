.main-navbar {
  padding: 0px;
  filter: drop-shadow(0 2px 4px #00000026);
}

.main-logo {
  width: 4rem;
  height: auto;
  margin-left: 0.5rem;
}

.login-button {
  margin-right: 2.5rem;
  margin-left: 0.5rem;
}

.brand-title {
  font-size: 2rem;
  font-weight: 900;
  color: #5f565e !important;
  margin: 1rem 0px 1rem 2.5rem;
  padding: 0px;
}

.main {
  margin-top: 2.5rem;
  margin-bottom: 2.5rem;
  background-color: white;
  filter: drop-shadow(0 2px 4px #00000026);
  max-width: 90% !important;
}

.left-column {
  border-right: 1px solid #cdcace;
  padding: 1.5625rem 1.5rem;
}

.footer {
  background-color: white;
  height: 4.5rem;
  border-top: 1px solid#cdcace;
}

.footer > div:first-child {
  border-right: 1px solid #cdcace;
  height: 100%;
}

.save-button {
  margin-left: 1.5rem;
  margin-right: 0.5rem;
}

.save-as-button {
  margin-right: 2rem;
}

.calculate-button {
  margin-right: 1.5rem;
  margin-left: 2.5rem;
}

.calculation-input {
  height: 2.5rem;
  width: 15rem;
  margin-left: 1rem;
}

.result-modal {
  max-width: 70vw;
}

.result-modal-body {
  min-height: 40vh;
  max-height: 75vh;
}

.flex-column {
  flex-direction: column;
}

.loading-spinner {
  color: #0096a9;
}

.loaded-svg {
  max-height: 60vh;
}

.svg-container > svg {
  width: 65vw;
  height: 65vh;
}

.spinner-container {
  margin-bottom: 4rem;
}

.collapse-button {
  cursor: pointer;
  margin-bottom: 1.5rem;
  color: #5f565e;
}

.collapse-title,
.modal-title {
  font-size: 1.5rem;
}

.rotate {
  transform: rotate(90deg);
}

.select-field {
  max-width: 11.5rem;
}

.input-field {
  max-width: 7.25rem;
}

.mb-05 {
  margin-bottom: 0.5rem;
}

.collapse-body {
  margin-left: 1.625rem;
}

.collapse-icon {
  width: 1.125rem !important;
  height: 1.125rem !important;
  margin-right: 0.5rem;
}

.wrapper-test {
  position: relative;
}

.input-text-addon {
  background-color: white;
  border-top: 1px solid #ced4da;
  border-right: 1px solid #ced4da;
  border-bottom: 1px solid #ced4da;
  border-left: none !important;
}

.border-right-none {
  border-right: none !important;
}

.orientation-input {
  border: 1px solid #cdcace;
  border-radius: 0.25rem;
  padding: 0.25rem;
}

.orientation-button {
  cursor: pointer;
  width: 2rem;
  height: 2rem;
  background-color: #e6e5e6;
  border-radius: 0.125rem;
}

.label-row > p {
  font-size: 1.25rem;
  color: #7e777f;
}

.obstacle-field {
  margin-right: 1rem;
}

.base-modal {
  max-width: 25rem;
}

.modal-title {
  color: #5f565e;
}

.modal-input-row {
  margin-bottom: 0.5rem;
}

.modal-form {
  margin-left: 1.5rem;
}

.measure-label {
  font-size: 0.95rem;
  color: #7e777f;
  font-weight: 400;
  margin-bottom: 0.4rem;
}

.mb-15 {
  margin-bottom: 1.5rem;
}

.rectangle {
  width: 2rem;
  height: 1.25rem;
  background-color: #e6e5e6;
  border: 2px solid #5f565e;
  margin-left: 0.77rem;
  margin-right: 0.77rem;
  margin-top: 1rem;
}

.seat-inputs-middle {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
  width: 5.5rem;
}

.seat-input {
  margin-left: 12.75rem;
}

.hidden {
  display: none;
}

.login-input-label {
  margin-left: 1.5rem;
}

.login-modal {
  margin-left: 1.5rem;
}

.registration-checkbox {
  margin-left: 0px;
}

.registration-text {
  font-size: 0.875rem;
  color: #7e777f;
}

.privacy-link {
  color: #0096a9;
  text-decoration: underline;
}

.verify-text {
  color: #5f565e;
  font-size: 1rem;
  font-weight: 500;
}

.try-again-text {
  font-size: 0.875rem;
  color: #7e777f;
}

.try-again-span {
  color: #0096a9;
  text-decoration: underline;
  cursor: pointer;
}

.caution-text {
  font-size: 0.875rem;
  color: #7e777f;
  margin-top: 0.5rem;
  margin-bottom: 0px;
  vertical-align: baseline;
}
